.active{
  /* background-color: red; */
  
}

nav {
  display: flex;
  justify-content:space-between;
  align-items:center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  
}

nav .title{
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}

nav ul li a:not(.active):hover{
  background-color: #172554
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 1.5em;

}

nav .menu span{
  height: 0.2rem;
  width: 100%;
  background-color: #FFF;
  border-radius: 0.2rem;
}

nav .profile{
  display:flex;
  align-items: center;
  /* border: 1px solid #ffffff33; */
  border-radius: 10px;
  justify-content: center;
}

nav .profile .icon{
  color: #fff;
}

@media (max-width: 480px) {
    nav .menu {
      display: flex !important;
    }

    nav {
      flex-direction: column;
      align-items: start;
    }

    nav ul {
      display: none;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.25rem ;
    }
    
    nav ul.open {
      display: flex;
      transition: transtate .18s ease-out;
      translate: 0;
    }

    nav ul li {
      width: 100%;
      text-align: center;
    }

    nav ul li a {
      margin: 0.2rem 0.5rem
    }
}