.wrapper {
    width: 350px;
    background: rgba(0,0,0, .3);
    color: #fff;
    height: 450px;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255, .2);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px rgba(255,255,255, .2) ;
    display: flex;
    align-items: center;
    position: relative;
    transition: height .2s ease;
    overflow: hidden;
}

.wrapper.active {
    height: 560px;
}

.wrapper .form-box{
    width: 100%;
    padding: 40px
}

.wrapper .form-box.login {
    transition: transtate .18s ease-out;
    translate: 0;
}

.wrapper.active .form-box.login {
    transition: none;
    translate: -400px;
}

.wrapper .form-box.register {
    position: absolute;
    transition:none;
    translate: 400px;
}

.wrapper.active .form-box.register {
    transition: translate .18s ease;
    translate: 0;
}

form h1 {
    font-size: 36px;
    text-align: center;
}




form .input-box {
    position:relative ;
    width: 100%;
    height:50px;
    margin:  30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    background:transparent;
    outline:  none;
    border:  2px solid rgba(255,255,255, .2);
    border-radius: 40px;
    color: #fff;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
    color: #fff;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    translate: 0 -50%;
    font-size: 16px;
}

form .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.remember-forgot label input {
    accent-color: #fff;
    margin-right:  4px;
}

.remember-forgot a {
    color: #fff;
    text-decoration: none;
}

.remember-forgot a:hover{
    text-decoration: underline;
}

form button {
    width: 100%;
    height: 45px;
    background: #fff;
    border:  none;
    outline:  none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;   
}

form .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover{
    text-decoration: underline;
    cursor: pointer;
}